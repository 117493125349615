.nav--default {
    background: linear-gradient(320deg, rgb(167, 67, 132), rgb(238, 5, 48));
}

.nav--brand {
  // font-family: 'Baloo Bhaina 2', Courier, monospace;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: -3px;
  font-size: 2.25rem;
  padding-bottom: 0px;
  padding-top: 0px;
}

.icon--navButton {
    margin-bottom: -3px !important;
}


.navbar--dark {
  .nav--default {
    background: linear-gradient(320deg, rgb(18, 20, 24), 90%, rgb(5, 191, 238));
    box-shadow: 0px 2px 10px 2px rgb(18, 20, 24);
  }
}

.body--dark {
  background-color: #3b3b3b;
}

.btns--dark {
  button.btn-primary {
      background-color: #2c81b3 !important;
      border-color: #2c81b3 !important;
  }
  button.btn-warning {
    background-color: lightslategray !important;
    border-color:grey !important;
    &:active {
      background-color: grey !important;
      box-shadow: 2px #3b3b3b !important;
    }
    svg {
      color: white;
    }
  }
  button.btn-info {
    background-color: deepskyblue;
    border-color:deepskyblue;
  }
}

.tabs--dark {
  .nav-link {
    color: #33c2fd;
  }
  .nav-link:hover {
    color: #4a9abd;
  }
}

.cards--dark {
  .card, .list-group-item {
    background-color: #4d4d4d;
  }
}

.modals--dark {
  .modal-content {
    background-color: #4d4d4d;
    .dayPicker__day.dayPicker__day--active {
      background-color: deepskyblue;
      background-image: linear-gradient(45deg, deepskyblue,rgb(0, 162, 255));

    }

    .dayPicker__day {
      background-color: #6d6d6d;
      &:hover {
        background-color: deepskyblue;
      }
    }

    .dayPicker__day.dayPicker__day--disabled {
      background-color: #6d6d6d;
    }
    small {
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        color: #b9b9b9;
      }
    }
  }
  .react_time_range__track {
    background-color: deepskyblue;
    background-image: linear-gradient(90deg, deepskyblue, rgb(0, 104, 223));
    background-image: linear-gradient(90deg, deepskyblue, #bf27b4);
    background-attachment: fixed;
    border-left: 1px solid rgb(14, 177, 231) !important;
    border-right: 1px solid rgb(14, 177, 231) !important;
    
  }

  .react_time_range__handle_marker {
    background-color: #35a7ce;
  }

}


.forms--dark {
  input {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: azure;
    background-color: #585c61;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    &:active, &:focus {
      display: block;
      padding: .375rem .75rem;
      font-size: 1rem;
      line-height: 1.5;
      color: azure;
      background-color: #4c535a;
      background-clip: padding-box;
      border-radius: .25rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
  }
}