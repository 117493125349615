.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.recentlyCompleted {
  max-height: 300px;
  text-align: left;
  overflow-y: scroll;
}

.heroSignUp {
  &--text-danger {
    color: #ffb8b8 !important;
    font-weight: bold !important;
    display: block !important;
  }
}


.card {
  &__center {
    margin: 0 auto;
  }
}

.icon--button {
  margin-bottom: -2px !important;
}

.icon--cardHeader {
  margin-bottom: -3px;
}

.icon--white {
  color: indianred;
}

.timeSelector {
  &--container {
    display: flex;
  }
  &--separator {
    margin: auto 0;
  }
}

.timeRangeSelector {
  width: 100%;
  height: 60px !important;
  border: 1px solid lightslategray;
  &--container {
    width: 100%;
    height: 60px !important;
    border: 1px solid lightslategray;
    border-radius: 3px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  &--header {
    display: flex;
    align-items: center;
    small {
      margin-bottom: 4px;
      &:hover {
        cursor: pointer;
        color: #4b4b4b;
        text-decoration: underline;
      }
    }
  }
  &__intervalType {
    &--container {
    }
  }
}

.dayPicker {
  &__container {
    display: flex;
    margin-bottom: 6px;
  }
  &__day {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: darkslategray;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    &:hover {
      cursor: pointer;
      background-color: orange;
    }
    &--active {
      background-color: orange;
    }
    &--disabled {
      &:hover {
        cursor: not-allowed;
        background-color: darkslategrey;
      }
    }
    &--text {
      color: #fff;
    }
  }

}

.btn {
  &--orange {
    background-color: orange;
    color: #fff !important;
  }
  // &--turquoise {
  //   background-color: #3fd073 !important;

  // }
}

.workSchedule {
  &--container {
    &:not(:first-of-type) {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
  &__addSchedule {
    &--warning {
      margin-top: 4rem;
    }
  }
}

.addSiteContainer {
  &--goodSites {
    margin-top: 3rem;
  }
  margin-bottom: 3rem;
}

.addReminder--container {
  margin-bottom: 3rem;
}

.reminderControls {
  cursor: default;
}

.addSite {
  &--label {
    display: flex;
    align-items: center;
  }
}

.list-group-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  small {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
      color: #4b4b4b;
    }
  }
}

.heroImage {
  background-image: url('../src/assets/landing-page/stay-focused-2.jpg');
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.55);
  background-position: top;
  background-size: cover;

  @media only screen and (min-width: 480px) {
    height: 736px;
  }

  @media only screen and (min-width: 676px) {
    height: 636px;
  }

  @media only screen and (min-width: 742px) {
    height: 620px;
  }

  border-radius: 0 !important;
  h1 {
    font-size: 64px;
    padding-top: 8vh;
  }
}



.setWorkingHours, .blockDistractingSites, .setReminders {
  h1 {
    font-size: 46px;
  }
}

.col {
  &--center{
    margin: 0 auto;
  }
}

.secondaryNav {
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 568px) {
    flex-direction: row;
  }

  button {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  // border-bottom: 1px solid slategrey;
}

.statPeriod {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3rem;
  @media (min-width: 568px) {
    flex-direction: row;
  }

  select {
    width: 100%;
    @media (min-width: 568px) {
      width: auto;
    }
  }
}


.homeBtn--dark {
  background-color: lightslategray;
  border: 1px solid grey;
  svg {
    color: whitesmoke;
  }
  .icon--white {
    color: whitesmoke;
  }

}

.profileBtn--dark {
  background-color: deepskyblue;
  color: white;
}

.card--dark {
  color: white;
  background: linear-gradient(45deg, #1b1b1b, transparent);
  background-color: #343a40!important;
}

.primaryBtn--dark {
  background-color: #2a8de0;
  border: 1px solid #2779bd;
}

.navbar__dark {
  background: linear-gradient(320deg, #162233, #1b1b1b);
}



.valueProp {
  &--list {
    list-style-type: none;
    font-size: 26px;
    padding:0;

    @media(min-width: 568px) {
      margin: 25% auto;
    }
    li {
      text-align: center;
    }

  }
}

.learnMore {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

html {
  scroll-behavior: smooth;
}

.footer {
  height: 100px;
  background: linear-gradient(320deg, #ee0530, #a74384);
}

.footer--absolute {
  bottom: 0;
  height: 100px;
  background: linear-gradient(320deg, #ee0530, #a74384);
}