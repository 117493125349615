.bar-chart {
    background: #fff;
    max-width: 900px;
    position: relative;
    
    .bars-row {
        padding: 0 0 0 50px;
        display: flex;
        justify-content: space-around;
        height: 100%;

        .bar {
            min-width: 10px;
            max-width: 100px;
            height: 100%;
            flex: 1 1 100px;
            padding: 0 8px;
            position: relative;
            .fill {
                background: #3eaabc;
            }
            .label {
                position: absolute;
                width: 100%;
                text-align: center;
                left: 0;
                font-size: 16px;
                padding: 3px;
                box-sizing: border-box;
                font-weight: bold;
            }
            &.negative .label {
                transform: translateY(-100%);
            }
        }
        
    } 

    .xaxis-line {
        height: 1px;
        position: absolute;
        left: 50px;
        right: 0;
        transform: translateY(-1px);
        background: #ccc;
    }

    .ygrid-item {
        position: absolute;
        left: 0;
        right: 0;
        display: flex;
        
        .figure {
            font-size: 12px;
            color: rgb(197, 197, 197);
            width: 50px;
            padding: 0 5px 0 0; 
            box-sizing: border-box;
            transform: translate(0, 7px);
            display: block;
            text-align: right;
        }

        .line {
            border-bottom: 1px solid #f5f5f5;
            flex: 1 1 auto;
            display: block;
        }

        &.zero .line {
            border-bottom: 1px solid #aaa; z-index: 5;
        }
    }

}

.bar-chart { //just for demo
    max-width: 900px;
    margin-bottom: 40px;
    margin-top: 40px;
    background: #fff;
}

