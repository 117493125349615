// html,
// body {
//   height: 100%;
//   margin: 0;
// }

// #root {
//   display: flex;
//   flex-direction: column;
//   height: 100%;
//   & > div {
//     width: 100%;
//   }
// }

// .container {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   height: 100%;
// }

.info {
    margin-left: 1%;
//   font-family: sans-serif;
//   color: #606367;
//   font-size: 12px;
//   font-weight: lighter;
//   margin: 20px 0;
//   & span:first-child {
//     margin-right: 10px;
//   }
  & span:last-child {
    &::before {
      content: '–';
      margin: 0 4px;
    }
  }
}

.react_time_range__time_range_container {
    padding: 30px 2% 0 !important;
    height: 70px;
    width: 100% !important;
    /* width: 90%; */
    box-sizing: border-box;
}

